<template>
  <div class="common-address">
    <el-cascader v-model="selectModel" :props="{ checkStrictly: checkStrictly }" :options="list" :disabled="disabled" :style="styleObj" clearable @change="change" />
  </div>
</template>

<script>
export default {
  name: 'CommonAddress',
  props: {
    styleObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    checkStrictly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addressSelected: {
      type: Array,
      default: () => {
        return []
      }
    },
    driverAddressSelected: {
      type: String,
      default: () => {
        return ''
      }
    }

  },
  data() {
    return {
      msg: '请选择省市区',
      list: [],
      selectModel: '',
      props: {
        label: '',
        children: 'children'
      }
    }
  },
  watch: {
    addressSelected: {
      handler(val) {
        this.selectModel = val
      },
      immediate: true,
      deep: true
    },
    driverAddressSelected: {
      handler(val) {
        this.selectModel = val
      },
      immediate: true,
      deep: true
    }

  },
  created() {
    this.initAddressArea()
  },
  methods: {
    initAddressArea() {
      this.$http.post('api/ContactUs/GetAreaComboBox', {}).then(res => {
        this.list = res.data
        console.log(this.list, '555555555555555555555555555555')
      }).catch(error => { console.log(error) })
    },
    change(data) {
      console.log(data, 'changedata,changedata，changedata，changedata')
      this.recursion(data)
    },
    recursion(data) {
      const arr = []
      const fun = (list, index) => {
        list.forEach(item => {
          if (item.key === data[index]) {
            arr.push(item.value)
            if (item.childList && item.childList.length > 0) {
              fun(item.childList, ++index)
            }
          }
        })
      }
      fun(this.list, 0)
      console.log(data, arr, '999999999999999')
      this.$emit('change', data, arr)
    }
  }
}
</script>

<style lang="scss">
.common-address{
  .is-disabled{
    input{
      background: #fff !important;
    }
  }
}
</style>
